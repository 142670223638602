import React from "react"
import MainLayout from "../components/MainLayout"
import ProductCard from "../components/ProductCard"
import Image1 from "../images/green-high.png"
import Image2 from "../images/260-tan.png"
import { ProductsPageStyled } from "../components/styles/ProductsPageStyled"
import PageHeading from "../components/PageHeadings"
import DemoSection from "../components/DemoSection"

const MSeriesPage = () => {
  return (
    <MainLayout>
      <ProductsPageStyled>
        <div>
          <PageHeading
            pageHeading="M-Series"
            // description="cray direct trade  readymade narwhal YOLO cornhole Kickstarter kitsch wayfarers artisan synth Vice seitan polaroid food truck Banksy fingerstache Thundercats art party cred chia next level Pinterest slow-carb before  Life disrupt"
          />

          <div id="product-card-container">
            <ProductCard
              image={Image1}
              heading="M17D"
              description="here is a brief description of the product and what it looks like when it wraps"
            />
            <ProductCard
              image={Image2}
              heading="M20D"
              description="here is a brief description of the product and what it looks like when it wraps"
            />
            <ProductCard
              image={Image1}
              heading="M20O"
              description="here is a brief description of the product and what it looks like when it wraps"
            />
            <ProductCard
              image={Image1}
              heading="M20O"
              description="here is a brief description of the product and what it looks like when it wraps"
            />
          </div>
        </div>
      </ProductsPageStyled>
      <DemoSection />
    </MainLayout>
  )
}

export default MSeriesPage
